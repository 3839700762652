import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Button } from "./text/Button"
import { H2 } from "./text/H2"
import { OutsideLink } from "./text/OutsideLink"
import { Paragraph } from "./text/Paragraph"

type Inputs = {
  name: string
  email: string
  newsletter: boolean
  legalRead: boolean
  prototyp: boolean
}

export interface NewsletterFormProps {
  onCancel?: () => void | Function
  className?: string
}

const NewsletterForm: React.SFC<NewsletterFormProps> = ({
  onCancel,
  className,
}) => {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm<Inputs>({
    defaultValues: { newsletter: false, legalRead: false, prototyp: true },
  })

  useEffect(() => {
    reset()
  }, [onCancel, reset])

  const onSubmit = async (data: Inputs) => {
    data.prototyp = true
    data.newsletter = true
    setLoading(false)

    const newsletterResponse = await fetch("/.netlify/functions/form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    if (newsletterResponse.status === 200) {
      setLoading(true)
      onCancel && onCancel()
      navigate("/bestaetigung")
    } else {
      setLoading(true)
      navigate("/fehler")
    }
  }
  return (
    <div className={className}>
      <H2 text="Sie möchten wissen, wie es weitergeht?" />
      <Paragraph>
        Möchten Sie Zuerst wissen, wenn es Neuigkeiten bezüglich unserer App
        gibt?
        <br />
        Dann tragen Sie gerne Ihre E-Mail Adresse und Ihren Namen in das
        untenstehende Formular.
      </Paragraph>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
            type="text"
            name="name"
            placeholder="Ihr vollständiger Name"
            ref={register({ required: true })}
          />
          {errors.name && (
            <p className="text-red-600">
              Bitte geben Sie Ihren vollständigen Namen ein
            </p>
          )}
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            E-Mail
          </label>
          <input
            className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
            type="mail"
            name="email"
            placeholder="Ihre E-Mail Adresse"
            ref={register({
              required: true,
              pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
            })}
          />
          {errors.email && (
            <p className="text-red-600">
              Bitte geben Sie eine gültige E-Mail an
            </p>
          )}
        </div>
        <Paragraph>
          Wenn Sie möchten, informieren wir Sie einmal im Monat in einem
          Newsletter über den aktuellen Stand des Projekts. Vom Newsletter
          können Sie sich auch jederzeit wieder abmelden.
        </Paragraph>
        <div className="mb-6">
          <label>
            <input
              name="legalRead"
              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
              ref={register({ required: true })}
              type="checkbox"
            />
            <span className="ml-1">
              Ich stimme zu, dass mir der Newsletter gesendet wird. Ich willige
              in die Speicherung der Daten auf diesem Formular ein. Die{" "}
              <OutsideLink
                href="http://diepsychotherapieapp.de/informationen-datenverarbeitung"
                input="Informationen zur Datenverarbeitung"
              />{" "}
              habe ich zur Kenntnis genommen. Diese Zustimmung erfolgt
              freiwillig und kann jederzeit mit Wirkung für die Zukunft
              widerrufen werden. Es entstehen keine Nachteile, wenn Sie nicht
              einwilligen. Den Newsletter können wir Ihnen dann nicht zusenden.
            </span>
          </label>
          {errors.legalRead && (
            <p className="text-red-600">
              Sie müssen zustimmen, um das Formular zu senden
            </p>
          )}
        </div>
        <div className={`mb-6 flex ${onCancel && "justify-around"}`}>
          <Button
            text="Senden"
            onClick={() => handleSubmit(onSubmit)}
            className="w-5/12"
            loading={loading}
          />
          {onCancel && (
            <Button
              text="Abbrechen"
              onClick={onCancel}
              className="w-5/12"
              secondary
            />
          )}
        </div>
      </form>
    </div>
  )
}

export default NewsletterForm
