import React from "react"
import Layout from "../components/Layout"
import NewsletterForm from "../components/NewsletterForm"
import Section from "../components/Section"
import { H1 } from "../components/text/H1"
import { Paragraph } from "../components/text/Paragraph"

export interface NewsletterBestaetigungProps {}

const NewsletterBestaetigung: React.SFC<NewsletterBestaetigungProps> = () => {
  return (
    <Layout>
      <Section>
        <div className="my-4 md:my-16">
          <H1 text="Leider gab es beim Übertragen Ihrer Informationen einen Fehler." />
          <Paragraph mb={12}>
            Bitte versuchen Sie es erneut oder schreiben Sie uns eine Mail
            (jonas@diepsychotherapieapp.de).
            <br />
            <br />
            <strong className="text-xl">
              Haben Sie sich bereits registriert?
              <br />
              Zur Überprüfung E-Mail Postfach durchsuchen.
            </strong>
          </Paragraph>
          <NewsletterForm />
        </div>
      </Section>
    </Layout>
  )
}

export default NewsletterBestaetigung
